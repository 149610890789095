<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'RequestDocumentModal' + idBid" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Создать запрос</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('RequestDocumentModal' + idBid)"></a>
			</template>

			<InputField
					:type-field="`textarea`"
					:name-field="`Комментарий`"
					v-model="comment" />

			<div class="mb-3px text-dark mt-24px">Прикрепить файл</div>
			<InputFile @setFiles="setFiles" />

			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="submitForm" class="btn btn-primary btn-lg">
					Отправить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import IconTrash from '@/components/icons/IconTrash'
  import axios from 'axios'
  import InputFile from '@/components/elements/InputFile'
  import InputField from "@/components/elements/Input.vue";

  export default {
    name: 'RequestDocumentModal',
    components: {InputField, InputFile, IconTrash },
    props: ['button', 'idOrder', 'idBid', 'isBid'],
    data() {
      return {
        files: [],
        comment: '',
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },

      token() {
        return this.$store.getters['auth/token']
      },
    },
    watch: {
    },
    mounted() {
    },
    methods: {
      setFiles(files) {
        this.files = files
      },

      showModal() {
        this.$bvModal.show('RequestDocumentModal' + this.idBid)
      },

      submitForm() {
        if (!this.files.length) {
          this.$notify({
            group: 'center',
            title: `Ошибка!`,
            text: 'Прикрепите файл',
            type: 'error',
          })
        }

        let formData = new FormData()

        this.files.forEach(file => {
          formData.append('document', file)
        })

        formData.append('document_type', 'bid_protocol_disagreement')
        formData.append('comment', this.comment)

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
          .then(res => res.data)
          .then(res => {
            if (res.message) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Документ успешно отправлен!`,
                type: 'success',
              })
              this.$bvModal.hide('RequestDocumentModal' + this.idBid)
            }
          })
      }
    },
  }
</script>

