<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'TicketModal' + idListener" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Создать тикет</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('TicketModal' + idListener)"></a>
			</template>
      <ValidationObserver
          tag="form"
          class="row mt-3"
          :ref="'ticket_modal_observer'"
          @submit.prevent="submitForm()"
          v-slot="{ invalid }"
          method="POST">

          <ValidationProvider
              tag="div"
              class="position-relative"
              :rules="'required'"
              :name="`Тема обращения`"
              v-slot="{ errors }">
              <InputField
                  :errors-field="errors"
                  :type-field="`text`"
                  :name-field="`Тема обращения`"
                  v-model="theme"/>
            	<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'ticket-theme-field_err'">{{ err }}</span>
							</div>
          </ValidationProvider>

          <div class="mt-16px text-dark">Департамент</div>
          <ValidationProvider
              class="position-relative"
              tag="div"
              rules="required"
              name="Департамент"
              v-slot="{ errors }">
            <v-select
                :class="{'is-invalid': errors.length}"
                :clearable="false"
                :searchable="false"
                :options="departments"
                v-model="department"></v-select>
            	<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'ticket-department-field_err'">{{ err }}</span>
							</div>
          </ValidationProvider>

          <ValidationProvider
              class="position-relative"
              tag="div"
              rules="required"
              name="Комментарий"
              v-slot="{ errors }">
              <div class="mt-16px">
                <InputField
                    :errors-field="errors"
                    :type-field="`textarea`"
                    :name-field="`Комментарий`"
                    v-model="comment"/>
              </div>
            	<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'ticket-comment-field_err'">{{ err }}</span>
							</div>
          </ValidationProvider>

          <div class="mb-3px text-dark mt-24px">Прикрепить файл</div>
          <InputFile @setFiles="setFiles"/>

          <div class="d-flex justify-content-center w-100 gap-3 mt-24px">
            <button type="submit" class="btn btn-primary btn-lg">
              Отправить
            </button>
          </div>
      </ValidationObserver>
      <div slot="modal-footer"></div>
		</b-modal>
    </span>
</template>

<script>
import IconTrash from '@/components/icons/IconTrash'
import axios from 'axios'
import InputFile from '@/components/elements/InputFile'
import InputField from "@/components/elements/Input.vue";

export default {
  name: 'TicketModal',
  components: {InputField, InputFile, IconTrash},
  props: ['button', 'idListener', 'idBid'],
  data() {
    return {
      files: [],
      comment: '',
      department: null,
      theme: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },

    token() {
      return this.$store.getters['auth/token']
    },

    departments() {
      let items = this.$store.getters['dictionaries/items']('departments')
      return items && items.length ? items.map(item => ({code: item.id, label: item.name})) : []
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch('dictionaries/fetchDictionary', {type: 'departments'})
  },
  methods: {
    setFiles(files) {
      this.files = files
    },

    showModal() {
      this.$bvModal.show('TicketModal' + this.idListener)
    },

    async submitForm() {
      const isValid = await this.$refs['ticket_modal_observer'].validate()
      if (isValid) {
        let formData = new FormData()

        if (this.files.length) {
          this.files.forEach(file => {
            formData.append('document', file)
          })
        }

        formData.append('message', this.comment)
        formData.append('theme', this.theme)
        formData.append('listener_id', this.idListener)
        formData.append('bid_id', this.idBid)
        formData.append('department_id', this.department.code)

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/tickets/create`
              break
            case 'sales_department_manager':
              path = `/mop/tickets/create`
              break
            case 'head_department_manager':
              path = `/rop/tickets/create`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
            .then(res => res.data)
            .then(res => {
              if (res.message) {
                this.$notify({
                  group: 'center',
                  title: `Ошибка!`,
                  text: res.message,
                  type: 'error',
                })
              } else {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: `Сообщение успешно отправлено!`,
                  type: 'success',
                })
                this.$bvModal.hide('TicketModal' + this.idListener)
              }
            })
      }
    }
  },
}
</script>

