import { render, staticRenderFns } from "./AddDocumentModal.vue?vue&type=template&id=78d1c11c"
import script from "./AddDocumentModal.vue?vue&type=script&lang=js"
export * from "./AddDocumentModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports