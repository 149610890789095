<template>
  <div>
    <div class="mb-25px">
      <router-link to="/orders/" class="d-inline-flex align-items-center text-decoration-none text-dark">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
          <path
              d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z"
              fill="#2B2A29"/>
        </svg>
        Назад
      </router-link>
    </div>
    <div v-if="bid && loaded">
      <div class="mw-1200px pb-30px">
        <!-- BEGIN page-header -->
        <OrderTitle :bid="bid" :id-bid="idBid" :id-order="idOrder"/>
        <!-- END page-header -->

        <PayerDetail :payment="payment" :id-bid="idBid" :id-order="idOrder"/>

        <Documents :id-order="idOrder" :id-bid="idBid" :is-bid="true" :available-actions="bid.available_actions"/>

        <div class="border border-2 border-radius-lg  px-24px pt-18px pb-24px mb-24px" v-if="bid">
          <div class="fs-24px fw-500 mb-16px">Состав заявки</div>

          <div class="bid-item">

            <div class="bg-success py-8px px-16px fs-14px lh-main text-white mb-16px border-radius-lg"
                 v-if="bid.program?.type_educational_program">{{ bid.program.type_educational_program.name }}
            </div>

            <div class="d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap mb-8px">
              <div class="d-flex flex-wrap align-items-center">
                <span class="me-24px fs-18px fw-600">Программа "{{ bid.program?.name }}"</span>
              </div>
              <div class="d-flex align-items-center mt-16px mt-md-0">
                <AddListenerModal :id-order="idOrder" :id-program="bid.configuration.id" :id-bid="idBid" :is-bid="true">
                  <template slot="button">
                    <IconAddUser class="ms-12px"/>
                  </template>
                </AddListenerModal>
                <DeleteProgramModal :id="bid.configuration.id" :id-bid="idBid" :id-order="idOrder" :is-bid="true">
                  <template slot="button">
                    <IconTrash class="ms-12px"/>
                  </template>
                </DeleteProgramModal>
              </div>
            </div>

            <template v-if="bid.listeners && bid.listeners.length">
              <Listeners :listeners="listenersWithConfirmDoc"
                         :bid="bid"
                         :id-order="idOrder"
                         :id-bid="idBid"
                         :id-program="bid.configuration.id"
                         :name-program="bid.program?.name"
                         :is-bid="true"
                         :can-send-access="true"
                         :can-edit-listeners="true"
                         :can-confirm-matter-docs="signed"
                         @setCheckedConfirm="setCheckedConfirm"/>
            </template>

            <div class="d-flex align-items-end flex-column mt-24px text-end"
                 v-if="bid.priceDiscont &&  bid.priceDiscont !== bid.price">
              <div class="text-muted mb-12px">Общая стоимость</div>
              <div class="fs-24px fw-600">{{ $formatPrice(bid.price) }} ₽</div>
            </div>
          </div>
        </div>

        <div class="border border-2 border-radius-lg  px-24px py-18px mb-24px" :id="'order_payment_block'"
             v-if="paymentsByOrderBid">
          <div class="d-flex justify-content-between mb-16px">
            <div class="fs-24px fw-500">Формат оплаты</div>
            <a href="javascript:;" @click.prevent="editPayment = true">
              <IconPen/>
            </a>
          </div>

          <template v-if="editPayment">
            <div class="d-flex flex-wrap mb-n3 w-100">
              <div class="me-96px mb-12px">
                <div class="form-check mb-8px" v-for="payment in paymentList" :key="`payment_${payment.code}`">
                  <input type="radio" @change="() => {paymentsByOrderBid.code = payment.code}" :name="'order_payment'"
                         :id="`order_payment_${payment.code}`" class="form-check-input" :value="payment.id"
                         v-model="paymentsByOrderBid.payment">
                  <label :for="`order_payment_${payment.code}`" class="form-check-label"> {{ payment.name }}</label>
                </div>
              </div>
              <div class="w-100 w-md-400px me-96px mb-20px position-relative"
                   v-if="paymentsByOrderBid.code === 'in_parts'">
                <div class="mb-8px">
                  Первый платеж будет 30%, а второй 70%
                </div>

                <!--								<div class="d-flex">
                                  <div class="me-24px">
                                    <label class="fs-12px m-4px">1я часть</label>
                                    <input type="text" :class="'form-control w-70px' + (paymentsByOrderBid.second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBid.first" @focus="paymentsByOrderBid.second_error = false" />
                                  </div>
                                  <div class="me-24px">
                                    <label class="fs-12px m-4px">2я часть</label>
                                    <input type="text" :class="'form-control w-70px' + (paymentsByOrderBid.second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBid.second" @focus="paymentsByOrderBid.second_error = false" />
                                  </div>
                                  <div>
                                    <label class="fs-12px m-4px">3я часть</label>
                                    <input type="text" :class="'form-control w-70px' + (paymentsByOrderBid.second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBid.third" @focus="paymentsByOrderBid.second_error = false" />
                                  </div>
                                </div>

                                <div v-if="paymentsByOrderBid.second_error" class="invalid-tooltip">Заполните поля</div>-->
              </div>
            </div>
            <div class="d-flex flex-wrap gap-3 w-100 mt-16px">
              <button type="button" class="btn btn-primary px-16px py-8px" @click="sendPayment">Сохранить</button>
              <button type="button" class="btn btn-gray-100 text-dark px-16px py-8px" @click="editPayment = false">
                Отмена
              </button>
            </div>
          </template>
          <div v-else>
            <div v-for="(payment, index) in bid.payments" :key="`payment_val_${payment.id}`">
              <div v-if="index === 0">{{ payment.payment_method_name }}</div>
            </div>
            <span v-for="(payment, index) in bid.payments" :key="`payment_val_percent_${payment.id}`"
                  v-if="payment.percent && payment.code === 'in_parts'">
							{{ payment.percent }}% {{ index + 1 !== bid.payments.length ? '/' : '' }}
						</span>
          </div>
        </div>

        <div class="border border-2 border-radius-lg  px-24px py-18px mb-24px"
             v-if="bid.loyalityProgram && bid.priceDiscont">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="fs-18px fw-600 mb-8px">Программа лояльности</div>
              {{ bid.loyalityProgram.name }} - {{ bid.loyalityProgram.percent }}%
            </div>
            <div class="col-auto col-md-4 col-lg-2">
              <div class="text-muted mb-12px">Общая стоимость</div>
              <div class="fs-24px fw-600 d-flex align-items-start">
                <span class="fs-14px text-overline text-dark-700 me-4px">{{ $formatPrice(bid.price) }} ₽</span>
                {{ $formatPrice(bid.priceDiscont) }} ₽
              </div>
            </div>
          </div>
        </div>

        <div class="mt-18px">
          <OrderTotal :price="bid.priceDiscont || bid.price"/>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loaded && !bid">
        Заявка не найдена
      </div>
    </div>
    <notifications group="center" position="top center" :duration="10000">
      <template slot="body" slot-scope="props">
        <div :class="'vue-notification-template vue-notification ' + props.item.type">
          <div class="title">
            {{ props.item.title }}
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-times"></i>
          </a>
          <div v-html="props.item.text">
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import PayerDetailsEditModal from '@/components/parts/order/modal/PayerDetailsEditModal'
import IconAddUser from '@/components/icons/IconAddUser'
import IconChevronDown from '@/components/icons/IconChevronDown'
import IconPen from '@/components/icons/IconPen'
import IconTrash from '@/components/icons/IconTrash'
import IconPlus from '@/components/icons/IconPlus'
import AddListenerModal from '@/components/parts/order/modal/AddListenerModal'
import DeleteProgramModal from '@/components/parts/order/modal/DeleteProgramModal'
import axios from 'axios'
import {scroller} from 'vue-scrollto/src/scrollTo'
import Documents from '@/components/parts/order/Documents'
import Listeners from '@/components/parts/order/Listeners'
import PayerDetail from '@/components/parts/order/PayerDetail'
import Manager from '@/components/parts/order/Manager'
import OrderTitle from '@/components/parts/order/OrderTitle'
import OrderTotal from '@/components/parts/order/OrderTotal'
import OrderTotalCP from '@/components/parts/order/OrderTotalCP'

export default {
  name: 'OrderDetail',
  components: {
    OrderTotalCP,
    OrderTotal,
    OrderTitle,
    Manager,
    PayerDetail,
    Listeners,
    Documents,
    DeleteProgramModal,
    AddListenerModal,
    IconPlus,
    IconTrash,
    IconPen,
    IconChevronDown,
    IconAddUser,
    PayerDetailsEditModal
  },
  data() {
    return {
      loaded: false,
      paymentsByOrderBid: {
        payment: null,
        /* first: '',
         second: '',
         third: '',
         second_error: false,*/
        code: '',
      },
      editPayment: false,
      listenersWithConfirmDoc: [],
    }
  },
  watch: {
    bid(newVal, oldVal) {
      let bid = newVal || []

      if (bid) {
        let paymentsByOrderBid = JSON.parse(JSON.stringify(this.paymentsByOrderBid))
        let payment = bid.payments
        if (payment && payment.length) {
          let paymentId = null
          let paymentCode = null
          for (let i = 0, l = payment.length; i < l; i++) {
            /*if (i === 0 && payment[i].payment_method_code === 'in_parts') {
              paymentsByOrderBid.first = payment[i].percent || paymentsByOrderBid.first
            }
            if (i === 0 && payment[i].payment_method_code === 'in_parts') {
              paymentsByOrderBid.second = payment[i].percent || paymentsByOrderBid.second
            }
            if (i === 0 && payment[i].payment_method_code === 'in_parts') {
              paymentsByOrderBid.third = payment[i].percent || paymentsByOrderBid.third
            }*/
            paymentId = payment[i].payment_method_id
            paymentCode = payment[i].payment_method_code
          }
          if (paymentId) {
            paymentsByOrderBid.payment = paymentId || paymentsByOrderBid.payment
          }
          if (paymentCode) {
            paymentsByOrderBid.code = paymentCode || paymentsByOrderBid.code
          }
        }
        this.paymentsByOrderBid = paymentsByOrderBid

        if (newVal.listeners) {
          this.listenersWithConfirmDoc = newVal.listeners.map(listener => {
            return {
              ...listener,
              personal_matter_documents_verified: listener.personal_matter_documents_verified || false,
            }
          })
        }
      }
    },
  },
  computed: {
    idOrder() {
      return this.$route.params.id
    },
    idBid() {
      return this.$route.params.id_bid
    },
    user() {
      return this.$store.getters['user/user']
    },
    token() {
      return this.$store.getters['auth/token']
    },
    bid() {
      return this.$store.getters['bids/itemById'](this.idBid)
    },
    payment() {
      return this.bid ? this.bid.payer_detail : null
    },
    manager() {
      return this.bid ? this.bid.manager : null
    },
    loyalityProgram() {
      return this.bid ? this.bid.loyalityProgram : null
    },
    paymentList() {
      return this.$store.getters['dictionaries/items']('payment_method')
    },
    documents() {
      return this.idOrder && this.idBid ? this.$store.getters['documents/itemsBids'](this.idOrder, this.idBid) : []
    },
    signed() {
      let signed = false
      if (this.documents && this.documents.length) {
        this.documents.forEach(doc => {
          if (doc.type_code === 'bid_contract' && doc.is_signed_by_contact_person) {
            signed = true
          }
        })
      }
      return signed
    }
  },
  mounted() {
    this.$store.dispatch('bids/fetchBidItem', {idOrder: this.idOrder, idBid: this.idBid})
        .then((res) => {
          this.loaded = true
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({path: '/login'})
            })
          }
        })
    this.$store.dispatch('dictionaries/fetchDictionary', {type: 'payment_method'})
    this.$store.dispatch('listeners/fetchListenerOrderItems', {idOrder: this.idOrder})
    this.$store.dispatch('documents/fetchDocumentsTypes')
    this.$store.dispatch('documents/fetchOrderBidDocumentsItems', {idOrder: this.idOrder, idBid: this.idBid})
  },
  methods: {
    setCheckedConfirm(listener_id) {
      this.listenersWithConfirmDoc.map(item => {
        if (item.id === listener_id) {
          item.personal_matter_documents_verified = true
        }
        return item
      })
    },
    sendPayment() {
      let idPayment = this.paymentsByOrderBid.payment
      let percent = []
      let errors = []

      /*if (this.paymentsByOrderBid.code === 'in_parts') {
        if (this.paymentsByOrderBid.first && this.paymentsByOrderBid.second && this.paymentsByOrderBid.third) {
          percent = [this.paymentsByOrderBid.first, this.paymentsByOrderBid.second, this.paymentsByOrderBid.third]
          this.paymentsByOrderBid.second_error = false
        } else {
          this.paymentsByOrderBid.second_error = true
        }
        if (Number(this.paymentsByOrderBid.first) < 30 || Number(this.paymentsByOrderBid.second) < 30) {
          errors.push(`При выборе оплаты по частям значения в первых двух полях должны быть не менее 30 процентов`)
        }
        if (Number(this.paymentsByOrderBid.first) + Number(this.paymentsByOrderBid.second) + Number(this.paymentsByOrderBid.third) !== 100) {
          errors.push(`При выборе оплаты по частям общая сумма частей должна быть равна 100 процентам`)
        }
      }*/

      if (errors.length) {
        this.$notify({
          group: 'center',
          title: ``,
          text: errors.join('<br/>'),
          type: 'error',
        })
      }

      if (!idPayment || errors.length) return false

      let path = ''
      let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/payments/${idPayment}/set`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/payments/${idPayment}/set`
            break
        }
      })

      const data = {
        percent: percent,
      }

      axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.token}`,
        },
        //data: data,
      })

          .then(res => res.data)
          .then(res => {

            if (res.message) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Сохранено`,
                type: 'success',
              })
              this.editPayment = false
              this.$store.dispatch('bids/fetchBidItem', {idOrder: this.idOrder, idBid: this.idBid})
            }
          })
    },
  },
}
</script>
