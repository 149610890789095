<template>
  <div>
    <!-- BEGIN page-header -->
    <div class="d-flex flex-wrap flex-row align-items-center justify-content-between gap-3 mb-30px mw-1200px">
      <h1 class="page-header mb-0">Политика конфиденциальности</h1>
    </div>
    <!-- END page-header -->

    <h4 class="h4">1. Общие положения</h4>
    Настоящая политика обработки персональных данных составлена в&nbsp;соответствии с&nbsp;требованиями Федерального
    закона от&nbsp;27.07.2006. №&nbsp;152-ФЗ «О&nbsp;персональных данных» (далее&nbsp;— Закон о&nbsp;персональных
    данных) и&nbsp;определяет порядок обработки персональных данных и&nbsp;меры по&nbsp;обеспечению безопасности
    персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее&nbsp;— Оператор).<br>
    1.1. Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей деятельности соблюдение прав и&nbsp;свобод
    человека и&nbsp;гражданина при&nbsp;обработке его&nbsp;персональных данных, в&nbsp;том&nbsp;числе защиты прав на&nbsp;неприкосновенность
    частной жизни, личную и&nbsp;семейную тайну.<br>
    1.2. Настоящая политика Оператора в&nbsp;отношении обработки персональных данных (далее&nbsp;— Политика) применяется
    ко&nbsp;всей информации, которую Оператор может получить о&nbsp;посетителях веб-сайта&nbsp;<a
      href="https://saes.nsu.ru/">https://saes.nsu.ru/</a><br>
    <h4 class="h4 mt-25px mb-12px">2. Основные понятия, используемые в Политике</h4>
    2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств
    вычислительной техники.<br>
    2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев,
    если обработка необходима для уточнения персональных данных).<br>
    2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных,
    обеспечивающих их доступность в сети интернет по сетевому адресу&nbsp;<a href="https://saes.nsu.ru/">https://saes.nsu.ru/</a><br>
    2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и
    обеспечивающих их обработку информационных технологий и технических средств.<br>
    2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования
    дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту
    персональных данных.<br>
    2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с
    использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор,
    запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
    (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
    данных.<br>
    2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или
    совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие
    цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции),
    совершаемые с персональными данными.<br>
    2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому
    Пользователю веб-сайта&nbsp;<a href="https://saes.nsu.ru/">https://saes.nsu.ru/</a><br>
    2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные,
    доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на
    обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке,
    предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).<br>
    2.10. Пользователь — любой посетитель веб-сайта&nbsp;<a href="https://saes.nsu.ru/">https://saes.nsu.ru/</a><br>
    2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному
    лицу или определенному кругу лиц.<br>
    2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных
    неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного
    круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в
    информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным
    способом.<br>
    2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного
    государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.<br>
    2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются
    безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе
    персональных данных и/или уничтожаются материальные носители персональных данных.<br>
    <h4 class="h4 mt-25px mb-12px">3. Основные права и обязанности Оператора</h4>
    3.1. Оператор имеет право:<br>
    — получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные
    данные;<br>
    — в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления
    обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку
    персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о
    персональных данных;<br>
    — самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения
    обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми
    актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.<br>
    3.2. Оператор обязан:<br>
    — предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных
    данных;<br>
    — организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;<br>
    — отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с
    требованиями Закона о персональных данных;<br>
    — сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую
    информацию в течение 10 дней с даты получения такого запроса;<br>
    — публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки
    персональных данных;<br>
    — принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или
    случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения
    персональных данных, а также от иных неправомерных действий в отношении персональных данных;<br>
    — прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и
    уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;<br>
    — исполнять иные обязанности, предусмотренные Законом о персональных данных.<br>
    <h4 class="h4 mt-25px mb-12px">4. Основные права и обязанности субъектов персональных данных</h4>
    4.1. Субъекты персональных данных имеют право:<br>
    — получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных
    федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них
    не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением
    случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее
    получения установлен Законом о персональных данных;<br>
    — требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если
    персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми
    для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;<br>
    — выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке
    товаров, работ и услуг;<br>
    — на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки
    персональных данных;<br>
    — обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке
    неправомерные действия или бездействие Оператора при обработке его персональных данных;<br>
    — на осуществление иных прав, предусмотренных законодательством РФ.<br>
    4.2. Субъекты персональных данных обязаны:<br>
    — предоставлять Оператору достоверные данные о себе;<br>
    — сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.<br>
    4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных
    без согласия последнего, несут ответственность в соответствии с законодательством РФ.<br>
  </div>
</template>

<script>
export default {
  name: 'AgreementPersonalData',
  data() {
    return {}
  },
}
</script>