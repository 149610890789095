<template>
  <div>

    <div class="form-check">
      <input type="checkbox"
             v-model="giveConsent"
             id="field-give-consent"
             @change="send"
             class="form-check-input" v-if="!giveConsent" />
        <span class="form-check-input checked form-check-input-bordered"
              :class="giveConsent ? 'checked' : ''" v-else/>
      <label class="form-label mb-0" for="field-give-consent">Подтверждаю <router-link to="/profile/agreement-personal-data"
                                                                     target='_blank'>согласие на обработку персональных данных</router-link></label>
    </div>

    <!-- START page notifications -->
    <notifications group="center" position="top center" :duration="10000">
      <template slot="body" slot-scope="props">
        <div :class="'vue-notification-template vue-notification ' + props.item.type">
          <div class="title">
            {{ props.item.title }}
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-times"></i>
          </a>
          <div v-html="props.item.text">
          </div>
        </div>
      </template>
    </notifications>
    <!-- END page notifications -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'GivenConsentProcessingPersonalDataElement',
  data() {
    return {
      giveConsent: false,
    }
  },
  computed: {
    token() {
      return this.$store.getters['auth/token']
    },
    user() {
      return this.$store.getters['user/user']
    },
  },
  watch: {
    user(newVal) {
      this.giveConsent = newVal.given_consent_processing_personal_data
    }
  },
  mounted() {
    this.giveConsent = this.user.given_consent_processing_personal_data
  },
  methods: {
    send() {
      this.giveConsent = true

      let path = '/listeners/personal/profile/give_consent_processing_personal_data'

      axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.token}`,
        },
      })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
              this.giveConsent = false
              return
            }
            this.$store.dispatch('user/fetchGetUser')
          })
          .catch(err => {
            this.giveConsent = false
          })
    }
  },
}
</script>
