<template>
  <div>
    <a href="#" @click.prevent="hasHistory() ? $router.go(-1) : $router.push('/')"
       class="d-inline-flex align-items-center text-decoration-none text-dark mb-30px">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
        <path
            d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z"
            fill="#2B2A29"/>
      </svg>
      Назад
    </a>
    <!-- BEGIN page-header -->
    <div class="d-flex flex-wrap flex-row align-items-center justify-content-between gap-3 mb-30px mw-1200px">
      <h1 class="page-header mb-0">Личное дело</h1>
    </div>
    <!-- END page-header -->
    <div class="mw-1200px" v-if="item">
      <div class="d-flex mb-24px">
        <button
            class="me-20px"
            :class="'btn' + (index === selectedTabIndex ? ' btn-primary' : '  btn-outline-primary')"
            v-for="(tab, index) in tabs"
            :key="`tab_${index}`"
            @click="selectTab(index)">{{ tab }}
        </button>
        <div class="ms-auto">
          <TicketModal :id-listener="idListener" :id-bid="idBid">
            <template slot="button">
            <span class="btn btn-gray-600">
              Создать тикет
            </span>
            </template>
          </TicketModal>
        </div>
      </div>

      <div v-show="selectedTabIndex === 0">

        <div class="table-responsive-md">
          <table class="table table-bordered mb-0 align-middle">
            <tr>
              <th>Наличие образования (высшего или средне профессионального) или получение такого вида образования в
                настоящее время
              </th>
              <td>{{ item.confirm_vo_spo ? 'Да' : 'Нет' }}</td>
            </tr>
            <tr>
              <th>Гражданство</th>
              <td>{{ item.citizenship === 'rus' ? 'Российское' : 'Другое' }}</td>
            </tr>
            <tr>
              <th>Фамилия</th>
              <td>{{ item.surname }}</td>
            </tr>
            <tr>
              <th>Имя</th>
              <td>{{ item.name }}</td>
            </tr>
            <tr>
              <th>Отчество</th>
              <td>{{ item.patronymic }}</td>
            </tr>
            <tr>
              <th>Телефон</th>
              <td>{{ $formatPhone(item.phone) }}</td>
            </tr>
            <tr>
              <th>СНИЛС</th>
              <td>{{ item.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`) }}</td>
            </tr>
            <tr>
              <th>Эл. адрес</th>
              <td>{{ item.email }}</td>
            </tr>
            <tr>
              <th>Полное ФИО в родительном падеже</th>
              <td>{{ item.genitive_fio }}</td>
            </tr>
            <tr>
              <th>Дата рождения</th>
              <td>{{ item.birthday }}</td>
            </tr>
            <tr v-if="item.passport">
              <th>{{ item.citizenship === 'rus' ? `Серия и номер паспорта` : `Удостоверение личности/иной документ` }}
              </th>
              <td>{{ item.passport.series_number.replace(/^(\d{4})(\d{6})$/g, `$1 $2`) }}</td>
            </tr>
            <tr v-if="item.passport && item.passport.date_issue">
              <th>Дата выдачи</th>
              <td>{{ item.passport.date_issue }}</td>
            </tr>
            <tr v-if="item.passport && item.passport.issued_by_whom">
              <th>Кем выдан</th>
              <td>{{ item.passport.issued_by_whom }}</td>
            </tr>
            <tr v-if="item.passport && item.passport.residential_address">
              <th>Адрес места жительства</th>
              <td>{{ item.passport.residential_address }}</td>
            </tr>
            <tr v-if="item.telegram">
              <th>Ник телеграм</th>
              <td>{{ item.telegram }}</td>
            </tr>
            <tr v-if="item.education">
              <th>Наличие образования</th>
              <td>
                {{ item.education.status === 'exist' ? 'Есть высшее / среднее профессиональное' : 'Получает высшее / среднее профессиональное' }}
              </td>
            </tr>
            <tr v-if="item.education && item.education.organization">
              <th>Образовательная организация</th>
              <td>{{ item.education.organization }}</td>
            </tr>
            <tr v-if="item.education && item.education.direction_training_name">
              <th>Направление подготовки</th>
              <td>{{ item.education.direction_training_name }}</td>
            </tr>
            <tr v-if="item.education && item.education.qualification_name">
              <th>Квалификация/специальность</th>
              <td>{{ item.education.qualification_name }}</td>
            </tr>
            <tr v-if="item.education && item.education.series_number_document">
              <th>Серия и номер диплома об образовании</th>
              <td>{{ item.education.series_number_document }}</td>
            </tr>
            <tr v-if="item.education && item.education.date_issue_document">
              <th>Дата выдачи документа об образовании</th>
              <td>{{ item.education.date_issue_document }}</td>
            </tr>
            <tr v-if="item.education && item.education.registration_number_document">
              <th>Рег. номер документа об образовании</th>
              <td>{{ item.education.registration_number_document }}</td>
            </tr>
            <tr v-if="item.education && item.education.fio_in_document">
              <th>ФИО в документе об образовании</th>
              <td>{{ item.education.fio_in_document }}</td>
            </tr>
            <tr v-if="item.labor_relations">
              <th>Статус наличия трудовых отношений</th>
              <td>
                {{ item.labor_relations.status === 'in_relationship' ? 'Состоит в трудовых отношениях' : 'Не состоит в трудовых отношениях' }}
              </td>
            </tr>
            <tr v-if="item.labor_relations && item.labor_relations.main_place_work">
              <th>Основное место работы</th>
              <td>{{ item.labor_relations.main_place_work }}</td>
            </tr>
            <tr v-if="item.labor_relations && item.labor_relations.job_title">
              <th>Должность</th>
              <td>{{ item.labor_relations.job_title }}</td>
            </tr>
            <tr v-if="item.labor_relations && item.labor_relations.job_category_name">
              <th>Категория должности</th>
              <td>{{ item.labor_relations.job_category_name }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="mw-900px" v-if="item.documents?.length" v-show="selectedTabIndex === 1">
        <div class="table-responsive-md">
          <table class="table table-bordered mb-0 align-middle">
            <tr v-for="field in item.documents" :key="`documents_form_${field.id}`">
              <th>{{ field.type_name }}</th>
              <td><a class="text-decoration-underline text-blue" :href="field.file_link" target="_blank"
                     :download="field.file_name">Скачать</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <notifications group="center" position="top center" :duration="10000">
      <template slot="body" slot-scope="props">
        <div :class="'vue-notification-template vue-notification ' + props.item.type">
          <div class="title">
            {{ props.item.title }}
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-times"></i>
          </a>
          <div v-html="props.item.text">
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import axios from 'axios'
import IconPen from '@/components/icons/IconPen'
import IconDocuments from '@/components/icons/IconDocuments'
import TicketModal from "@/components/parts/profile/TicketModal.vue";

export default {
  name: 'OrderListenerInfo',
  components: {TicketModal, IconDocuments, IconPen},
  data() {
    return {
      tabs: ['Персональные данные', 'Документы для личного дела'],
      selectedTabIndex: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['user/user']
    },
    roles() {
      return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
    },
    isListener() {
      return this.user && this.roles.indexOf('listener') >= 0
    },
    isContactPerson() {
      return this.user && this.roles.indexOf('contact_person') >= 0
    },
    isMop() {
      return this.user && this.roles.indexOf('sales_department_manager') >= 0
    },
    isRop() {
      return this.user && this.roles.indexOf('head_department_manager') >= 0
    },
    idListener() {
      console.log(this.$route.params)
      return this.$route.params.id_listener
    },
    idBid() {
      return this.$route.params.id_bid
    },
    item() {
      return this.idListener ? this.$store.getters['listeners/itemById'](this.idListener) : null
    },
  },

  watch: {},

  mounted() {
    this.$store.dispatch('listeners/fetchListenerItemInfo', this.idListener).then(() => {
      this.$store.dispatch('listeners/fetchListenerItemMatter', this.idListener)
    })
  },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },
    selectTab(index) {
      this.selectedTabIndex = index
    },
  },
}
</script>
