<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'AddDocumentModal' + idBid" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Прикрепить документы</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('AddDocumentModal' + idBid)"></a>
			</template>

			<div class="mb-3px text-dark">Тип документа</div>
			<v-select :options="documentTypesArray" :clearable="false" :searchable="false" v-model="selectedType"></v-select>

			<div class="mb-3px text-dark mt-24px">Прикрепить файл</div>
			<InputFile @setFiles="setFiles" :file-name="selectedType ? `${selectedType.label} №${idBid}` : ''"
                 :set-date="true"/>


			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="submitForm" class="btn btn-primary btn-lg">
					Отправить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
import IconTrash from '@/components/icons/IconTrash'
import axios from 'axios'
import InputFile from '@/components/elements/InputFile'

export default {
  name: 'AddDocumentModal',
  components: {InputFile, IconTrash},
  props: ['button', 'idOrder', 'idBid', 'isBid', 'availableActions'],
  data() {
    return {
      files: [],
      selectedType: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },

    documentTypes() {
      return this.$store.getters['documents/types']
    },

    documentTypesArray() {
      let types = []
      if (this.availableActions) {
        this.documentTypes.forEach(type => {
          switch (type.code) {
            case 'bid_contract':
              if (this.availableActions['can-add-bid_contract-document']) {
                types.push(type)
              }
              break
            case 'bid_protocol_disagreement':
              if (this.availableActions['can-add-bid_protocol_disagreement-document']) {
                types.push(type)
              }
              break
            case 'bid_additional_agreement':
              if (this.availableActions['can-add-bid_additional_agreement-document']) {
                types.push(type)
              }
              break
            case 'bid_payment':
              if (this.availableActions['can-add-bid_payment-document']) {
                types.push(type)
              }
              break
            case 'bid_letter_guarantee':
              if (this.availableActions['can-add-bid_letter_guarantee-document']) {
                types.push(type)
              }
              break
            case 'bid_bill':
              if (this.availableActions['can-add-bid_bill-document']) {
                types.push(type)
              }
              break
            case 'bid_act':
              if (this.availableActions['can-add-bid_act-document']) {
                types.push(type)
              }
              break
          }
        })
      }
      if (!this.availableActions) {
        types = this.documentTypes
      }
      return types.map(item => {
        return {
          label: item.name,
          code: item.code,
        }
      })
    },

    token() {
      return this.$store.getters['auth/token']
    },
  },
  watch: {
    documentTypesArray(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.selectedType = newVal.shift()
      }
    },
  },
  mounted() {
  },
  methods: {
    setFiles(files) {
      this.files = files
    },

    showModal() {
      this.$bvModal.show('AddDocumentModal' + this.idBid)
    },

    submitForm() {
      if (!this.files.length) {
        this.$notify({
          group: 'center',
          title: `Ошибка!`,
          text: 'Прикрепите файл',
          type: 'error',
        })
      }
      if (!this.selectedType) {
        this.$notify({
          group: 'center',
          title: `Ошибка!`,
          text: 'Выберите тип документа',
          type: 'error',
        })
      }

      let formData = new FormData()

      this.files.forEach(file => {
        formData.append('document', file)
      })

      formData.append('document_type', this.selectedType.code)
      formData.append('comment', 'comment')

      let path = ''
      let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
            break
        }
      })

      axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.token}`,
        },
        data: formData,
      })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Документ успешно отправлен!`,
                type: 'success',
              })
              this.$bvModal.hide('AddDocumentModal' + this.idBid)
              this.$store.dispatch('documents/fetchOrderBidDocumentsItems', {idOrder: this.idOrder, idBid: this.idBid})
            }
          })
    },
  },
}
</script>

