<template>
  <div>
    <div class="mb-25px">
      <router-link to="/orders/" class="d-inline-flex align-items-center text-decoration-none text-dark">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
          <path
              d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z"
              fill="#2B2A29"/>
        </svg>
        Назад
      </router-link>
    </div>
    <div v-if="order && loaded">
      <div class="mw-1200px pb-30px">
        <!-- BEGIN page-header -->
        <OrderTitleCP :order="order" :manager="manager"/>
        <!-- END page-header -->

        <PayerDetail :payment="payment" :id-order="order.id"/>

        <div class="border border-2 border-radius-lg  px-24px pt-18px pb-24px mb-24px" v-if="bids && bids.length">
          <div class="fs-24px fw-500 mb-16px">Состав заказа</div>

          <div :class="'order-item' + (index !== 0 ? ' mt-4' : '') " v-for="(bid, index) in bids"
               :key="'bids_' + bid.id">

            <div class="bg-success py-8px px-16px fs-14px lh-main text-white mb-16px border-radius-lg"
                 v-if="bid.program?.program_type">{{ bid.program.program_type.name }}
            </div>

            <div class="d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap gap-2 mb-8px">
              <span class="fs-18px fw-600">Заявка {{ index + 1 }}. Программа "{{ bid.program?.name }}"</span>
              <span :class="'border-radius-xl px-12px py-7px  sm-nowrap ' + status(bid.status.code)" v-if="bid.status">Статус: {{ bid.status.name }}</span>
              <div class="d-flex align-items-center mt-16px mt-md-0" v-if="order.can_edit_listeners">
                <AddListenerModal :id-order="idOrder" :id-program="bid.configuration.id" :id-bid="bid.id">
                  <template slot="button">
                    <IconAddUser class="ms-12px"/>
                  </template>
                </AddListenerModal>
                <DeleteProgramModal :id="bid.configuration.id" :id-bid="bid.id" :id-order="idOrder">
                  <template slot="button">
                    <IconTrash class="ms-12px"/>
                  </template>
                </DeleteProgramModal>
              </div>
            </div>

            <template v-if="bid.listeners && bid.listeners.length">
              <Listeners
                  :bid="bid"
                  :listeners="bid.listeners"
                  :can-edit-listeners="order.can_edit_listeners"
                  :can-send-access="order.can_send_access"
                  :id-order="$route.params?.id"
                  :id-bid="bid.id"
                  :id-program="bid.configuration.id"
                  :name-program="bid.program?.name"/>
            </template>

            <template v-if="paymentsByOrderBids[bid.id]">

              <div class="fs-18px fw-600 mt-16px mb-16px" :id="'order_payment_block_' + bid.id">Формат оплаты</div>

              <div class="d-flex flex-wrap mb-n3 w-100" v-if="order.can_edit_payer">
                <div class="me-96px mb-12px">
                  <div class="form-check mb-8px" v-for="payment in paymentList" :key="`payment_${payment.code}`">
                    <input type="radio" @change="() => {sendPayment(bid.id, payment.code)}"
                           :name="'order_payment_'+bid.id" :id="'order_payment_'+payment.id+'_1_' + bid.id"
                           class="form-check-input" :value="payment.id" v-model="paymentsByOrderBids[bid.id].payment">
                    <label :for="'order_payment_'+payment.id+'_1_' + bid.id" class="form-check-label">
                      {{ payment.name }}</label>
                  </div>
                </div>
                <div class="w-100 w-md-400px me-96px mb-20px position-relative"
                     v-if="paymentsByOrderBids[bid.id].code === 'in_parts'">
                  <div class="mb-8px">
                    Первый платеж будет 30%, а второй 70%
                  </div>
                  <!--
                                    <div class="d-flex">
                                      <div class="me-24px">
                                        <label class="fs-12px m-4px">1я часть</label>
                                        <input type="text" @change="sendPayment(bid.id)" :class="'form-control w-70px' + (paymentsByOrderBids[bid.id].second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBids[bid.id].first" @focus="paymentsByOrderBids[bid.id].second_error = false" />
                                      </div>
                                      <div class="me-24px">
                                        <label class="fs-12px m-4px">2я часть</label>
                                        <input type="text" @change="sendPayment(bid.id)" :class="'form-control w-70px' + (paymentsByOrderBids[bid.id].second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBids[bid.id].second" @focus="paymentsByOrderBids[bid.id].second_error = false" />
                                      </div>
                                      <div>
                                        <label class="fs-12px m-4px">3я часть</label>
                                        <input type="text" @change="sendPayment(bid.id)" :class="'form-control w-70px' + (paymentsByOrderBids[bid.id].second_error ? ' is-invalid' : '')" v-model="paymentsByOrderBids[bid.id].third" @focus="paymentsByOrderBids[bid.id].second_error = false" />
                                      </div>
                                    </div>

                                    <div v-if="paymentsByOrderBids[bid.id].second_error" class="invalid-tooltip">Заполните поля</div>-->
                </div>

                <div class="d-flex flex-column align-items-end mb-12px" v-if="bid.price">
                  <div>
                    Итого:
                    <div class="fw-500">{{ $formatPrice(bid.price) }}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-for="(payment, index) in bid.payments" :key="`payment_val_${payment.id}`">
                  <div v-if="index === 0">{{ payment.payment_method_name }}</div>
                </div>
                <span v-for="(payment, index) in bid.payments" :key="`payment_val_percent_${payment.id}`"
                      v-if="payment.percent && payment.code === 'in_parts'">
									{{ payment.percent }}% {{ index + 1 !== bid.payments.length ? '/' : '' }}
								</span>
              </div>
            </template>

            <Documents :id-order="$route.params?.id" :id-bid="bid.id" :available-actions="bid.available_actions"/>
          </div>
        </div>

        <a href="javascript:void(0)" @click="$router.push({ path: pathToAddProgram })"
           class="d-inline-flex align-items-center fw-500 mt-32px text-decoration-none text-dark"
           v-if="order.can_edit_payer">
					<span
              class="d-flex align-items-center justify-content-center w-50px h-50px border-radius-round bg-gray-back me-12px">
						<IconPlus/>
					</span>
          Добавить программу
        </a>

        <div class="d-flex align-items-end flex-column mt-24px text-end"
             v-if="order.priceDiscont && order.priceDiscont !== order.price">
          <div class="text-muted mb-12px">Итого по заказу</div>
          <div class="fs-24px fw-600">{{ $formatPrice(order.price) }} ₽</div>
        </div>

        <div class="border border-2 border-radius-lg  px-24px py-18px mb-24px"
             v-if="loyalityProgram && order.priceDiscont">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="fs-18px fw-600 mb-8px">Программа лояльности</div>
              {{ loyalityProgram.name }} - {{ loyalityProgram.percent }}%
            </div>
            <div class="col-auto col-md-4 col-lg-2">
              <div class="text-muted mb-12px">Общая стоимость</div>
              <div class="fs-24px fw-600 d-flex align-items-start">
                <span class="fs-14px text-overline text-dark-700 me-4px">{{ $formatPrice(order.price) }} ₽</span>
                {{ $formatPrice(order.priceDiscont) }} ₽
              </div>
            </div>
          </div>
        </div>

        <div class="mt-18px">
          <OrderTotalCP :price-discont="order.priceDiscont" :price="order.price"/>
          <div class="text-center mt-32px" v-if="order.can_send_to_verification">
            <a href="javascript:void(0)" @click.prevent="submitForm" class="btn btn-primary px-30px py-10px">
              Отправить на проверку
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loaded && !order">
        Заказ не найден
      </div>
    </div>
    <notifications group="center" position="top center" :duration="10000">
      <template slot="body" slot-scope="props">
        <div :class="'vue-notification-template vue-notification ' + props.item.type">
          <div class="title">
            {{ props.item.title }}
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-times"></i>
          </a>
          <div v-html="props.item.text">
          </div>
        </div>
      </template>
    </notifications>

    <b-modal :id="'MessageModal'" size="md" centered>
      <template slot="modal-header">
        <h4 class="modal-title w-100 text-center">Спасибо!</h4>
        <a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('MessageModal')"></a>
      </template>

      <div class="text-center">
        Заявка отправлена на согласование менеджеру отдела продаж<br/>
        В ближайшее время вам будет направлен договор для согласования
      </div>

      <div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
        <button type="button" @click="closeMessageModal" class="btn btn-primary btn-lg">
          OK
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PayerDetailsEditModal from '@/components/parts/order/modal/PayerDetailsEditModal'
import IconAddUser from '@/components/icons/IconAddUser'
import IconChevronDown from '@/components/icons/IconChevronDown'
import IconPen from '@/components/icons/IconPen'
import IconTrash from '@/components/icons/IconTrash'
import IconPlus from '@/components/icons/IconPlus'
import AddListenerModal from '@/components/parts/order/modal/AddListenerModal'
import DeleteProgramModal from '@/components/parts/order/modal/DeleteProgramModal'
import axios from 'axios'
import {scroller} from 'vue-scrollto/src/scrollTo'
import Documents from '@/components/parts/order/Documents'
import Listeners from '@/components/parts/order/Listeners'
import PayerDetail from '@/components/parts/order/PayerDetail'
import Manager from '@/components/parts/order/Manager'
import OrderTitleCP from '@/components/parts/order/OrderTitleCP'
import OrderTotalCP from '@/components/parts/order/OrderTotalCP'

const PAYMENT_FORM = {
  code: '',
  payment: null,
  /*first: '',
  second: '',
  third: '',
  second_error: false,*/
}

export default {
  name: 'OrderDetail',
  components: {
    OrderTotalCP,
    OrderTitleCP,
    Manager,
    PayerDetail,
    Listeners,
    Documents,
    DeleteProgramModal,
    AddListenerModal,
    IconPlus,
    IconTrash,
    IconPen,
    IconChevronDown,
    IconAddUser,
    PayerDetailsEditModal
  },
  data() {
    return {
      loaded: false,
      paymentsByOrderBids: {},
    }
  },
  watch: {
    order(newVal, oldVal) {
      let bids = newVal.bids || []
      if (bids && bids.length) {
        let paymentsByOrderBids = JSON.parse(JSON.stringify(this.paymentsByOrderBids))
        for (let i = 0, l = bids.length; i < l; i++) {
          let id = bids[i].id
          if (!paymentsByOrderBids[id]) {
            paymentsByOrderBids[id] = JSON.parse(JSON.stringify(PAYMENT_FORM))
          }
          let payments = bids[i].payments
          if (payments && payments.length) {
            let paymentId = null
            let paymentCode = null
            for (let i = 0, l = payments.length; i < l; i++) {
              /*if (i === 0 && payments[i].payment_method_code === 'in_parts') {
                paymentsByOrderBids[id].first = payments[i].percent || paymentsByOrderBids[id].first
              }
              if (i === 1 && payments[i].payment_method_code === 'in_parts') {
                paymentsByOrderBids[id].second = payments[i].percent || paymentsByOrderBids[id].second
              }
              if (i === 2 && payments[i].payment_method_code === 'in_parts') {
                paymentsByOrderBids[id].third = payments[i].percent || paymentsByOrderBids[id].third
              }*/
              paymentId = payments[i].payment_method_id
              paymentCode = payments[i].payment_method_code
            }
            if (paymentCode) {
              paymentsByOrderBids[id].code = paymentCode || paymentsByOrderBids[id].code
            }
            if (paymentId) {
              paymentsByOrderBids[id].payment = paymentId || paymentsByOrderBids[id].payment
            }
          }
        }
        this.paymentsByOrderBids = paymentsByOrderBids
      }
    },
  },
  computed: {
    idOrder() {
      return this.$route.params.id
    },
    user() {
      return this.$store.getters['user/user']
    },
    token() {
      return this.$store.getters['auth/token']
    },
    bids() {
      if (!this.order) return []
      return this.order.bids
    },
    payment() {
      return this.order ? this.order.payer_detail : null
    },
    manager() {
      return this.order ? this.order.manager : null
    },
    loyalityProgram() {
      return this.order ? this.order.loyalityProgram : null
    },
    order() {
      return this.idOrder ? this.$store.getters['orders/itemById'](this.idOrder) : null
    },
    pathToAddProgram() {
      return this.idOrder ? `/orders/${this.idOrder}/add-program/` : ''
    },
    paymentList() {
      return this.$store.getters['dictionaries/items']('payment_method')
    },
  },
  mounted() {
    this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
        .then((res) => {
          this.loaded = true
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout')
            ]).then(() => {
              this.$router.push({path: '/login'})
            })
          }
        })
    this.$store.dispatch('dictionaries/fetchDictionary', {type: 'payment_method'})
    this.$store.dispatch('listeners/fetchListenerOrderItems', {idOrder: this.idOrder})
    this.$store.dispatch('documents/fetchDocumentsTypes')
  },
  methods: {
    status(code) {
      switch (code) {
        case 'waiting_auth_contact_face':
          return 'bg-warning text-dark'
        case 'waiting_verification_by_contact_person':
          return 'bg-warning text-dark'
        case 'waiting_auth_listeners':
          return 'bg-warning text-dark'
        case 'waiting_listeners_fill_profile':
          return 'bg-warning text-dark'
        case 'waiting_listener_consent_program':
          return 'bg-warning text-dark'
        case 'signing_contract':
          return 'bg-yellow text-dark'
        case 'waiting_payment':
          return 'bg-warning text-dark'
        case 'payed':
          return 'bg-success text-dark'
        default:
          return 'bg-yellow text-dark'
      }
    },
    sendPayment(idBid, code) {
      let idPayment
      let percent = []
      if (code) {
        this.paymentsByOrderBids[idBid].code = code
      }
      idPayment = this.paymentsByOrderBids[idBid].payment
      /*for (let key in this.paymentsByOrderBids) {
        if (this.paymentsByOrderBids.hasOwnProperty(key)) {
          if (this.paymentsByOrderBids[key].code === 'in_parts') {
            if (this.paymentsByOrderBids[key].first && this.paymentsByOrderBids[key].second && this.paymentsByOrderBids[key].third) {
              idPayment = this.paymentsByOrderBids[key].payment
              percent = [this.paymentsByOrderBids[key].first, this.paymentsByOrderBids[key].second, this.paymentsByOrderBids[key].third]
            }
          } else {
            idPayment = this.paymentsByOrderBids[key].payment
          }
        }
      }*/
      if (!idPayment) return false

      let path = ''
      let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${this.idOrder}/bids/${idBid}/payments/${idPayment}/set`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/${idBid}/payments/${idPayment}/set`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/${idBid}/payments/${idPayment}/set`
            break
        }
      })

      const data = {
        percent: percent,
      }

      axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.token}`,
        },
        //data: data,
      })
    },
    submitForm() {
      let isValid = true
      let errors = []
      /*for (let key in this.paymentsByOrderBids) {
        if (this.paymentsByOrderBids.hasOwnProperty(key) && this.paymentsByOrderBids[key].code === 'in_parts') {
          this.paymentsByOrderBids[key].second_error = false
          if (!this.paymentsByOrderBids[key].first || !this.paymentsByOrderBids[key].second || !this.paymentsByOrderBids[key].third) {
            this.paymentsByOrderBids[key].second_error = true
            isValid = false
            const scrollTo = scroller()
            scrollTo('#order_payment_block_' + key)
          }
          let name = ''
          for (let i = 0, l = this.bids.length; i < l; i++) {
            console.log(key, this.bids[i].id === key)
            if (Number(this.bids[i].id) === Number(key)) {
              name = 'Заявка ' + (i + 1)
            }
          }
          if (Number(this.paymentsByOrderBids[key].first) < 30 || Number(this.paymentsByOrderBids[key].second) < 30) {
            errors.push(`${name}. При выборе оплаты по частям значения в первых двух полях должны быть не менее 30 процентов`)
            isValid = false
          }
          if (Number(this.paymentsByOrderBids[key].first) + Number(this.paymentsByOrderBids[key].second) + Number(this.paymentsByOrderBids[key].third) !== 100) {
            errors.push(`${name}. При выборе оплаты по частям общая сумма частей должна быть равна 100 процентам`)
            isValid = false
          }
        }
      }*/

      if (errors.length) {
        this.$notify({
          group: 'center',
          title: ``,
          text: errors.join('<br/>'),
          type: 'error',
        })
      }

      if (isValid) {

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/send_to_verification`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/send_to_verification`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/send_to_verification`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
            .then(res => res.data)
            .then(res => {
              if (res.data && res.data.id) {
                this.$bvModal.show('MessageModal')
                this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
              } else if (res.message) {
                this.$notify({
                  group: 'center',
                  title: `Ошибка`,
                  text: res.message,
                  type: 'error',
                })
              }
            })
      }
    },
    closeMessageModal() {
      this.$bvModal.hide('MessageModal')
      this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
    }
  },
}
</script>
