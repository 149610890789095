<template>
  <div>
    <div class="mb-25px">
      <a href="#" @click.prevent="hasHistory() ? $router.go(-1) : $router.push('/')"
         class="d-inline-flex align-items-center text-decoration-none text-dark">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
          <path
              d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z"
              fill="#2B2A29"/>
        </svg>
        Назад
      </a>
    </div>

    <div class="mw-1200px">
      <!-- BEGIN page-header -->
      <div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
        <h1 class="page-header">{{ idListener ? 'Редактировать слушателя' : 'Добавить слушателя' }}</h1>
      </div>
      <!-- END page-header -->
      <ValidationObserver tag="form"
                          @submit.prevent="submitForm"
                          ref="observer-form-listener"
                          v-slot="{ invalid }"
                          method="POST">

        <div class="row">
          <ValidationProvider ref="form-program-available_vo_spo" tag="div" class="col-12 mb-15px position-relative"
                              rules="required" name="Наличие ВПО/СПО" v-slot="{ errors }">

            <div :class="'form-check' + (errors.length ? ' is-invalid' : '')">
              <input type="checkbox"
                     :disabled="signingContractStatus"
                     v-model="form['available_vo_spo']"
                     id="form-program-field-available_vo_spo"
                     class="form-check-input"/>
              <label for="form-program-field-available_vo_spo" class="form-label">Есть высшее или среднее
                профессиональное образование / Сейчас получаю</label>
            </div>
            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-available_vo_spo_err'">{{ err }}</span>
            </div>

          </ValidationProvider>

          <ValidationProvider ref="form-program-surname" tag="div" class="col-12 col-sm-4 mb-15px position-relative"
                              rules="required" name="Фамилия" mode="lazy" v-slot="{ errors }">

            <label for="form-program-field-surname" class="form-label">Фамилия*</label>

            <input type="text"
                   class="form-control"
                   :disabled="signingContractStatus"
                   :class="errors.length ? 'is-invalid' : ''"
                   placeholder="Введите фамилию"
                   id="form-program-field-surname"
                   v-model="form['surname']"/>

            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-surname_err'">{{ err }}</span>
            </div>

          </ValidationProvider>

          <ValidationProvider ref="form-program-name" tag="div" class="col-12 col-sm-4 mb-15px position-relative"
                              rules="required" name="Имя" mode="lazy" v-slot="{ errors }">

            <label for="form-program-field-name" class="form-label">Имя*</label>

            <input type="text"
                   class="form-control"
                   :disabled="signingContractStatus"
                   :class="errors.length ? 'is-invalid' : ''"
                   placeholder="Введите имя"
                   id="form-program-field-name"
                   v-model="form['name']"/>

            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-name_err'">{{ err }}</span>
            </div>

          </ValidationProvider>

          <ValidationProvider ref="form-program-patronymic" tag="div" class="col-12 col-sm-4 mb-15px position-relative"
                              rules="required" name="Отчество" mode="lazy" v-slot="{ errors }">

            <label for="form-program-field-patronymic" class="form-label">Отчество*</label>

            <input type="text"
                   class="form-control"
                   :disabled="signingContractStatus"
                   :class="errors.length ? 'is-invalid' : ''"
                   placeholder="Введите отчество"
                   id="form-program-field-patronymic"
                   v-model="form['patronymic']"/>

            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-patronymic_err'">{{ err }}</span>
            </div>

          </ValidationProvider>

          <ValidationProvider ref="form-program-snils" tag="div" class="col-12 col-sm-4 mb-15px position-relative"
                              rules="required|snils" name="СНИЛС" mode="lazy" v-slot="{ errors }">

            <label for="form-program-field-snils" class="form-label">СНИЛС*</label>

            <masked-input type="text"
                          class="form-control"
                          :disabled="signingContractStatus"
                          :class="errors.length ? 'is-invalid' : ''"
                          placeholder="___-___-___-__"
                          name="form-program-field-snils"
                          id="form-program-field-snils"
                          pattern="111-111-111-11"
                          v-model="form['snils']">

            </masked-input>

            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-snils_err'">{{ err }}</span>
            </div>

          </ValidationProvider>

          <ValidationProvider ref="form-program-email" tag="div" class="col-12 col-sm-4 mb-15px position-relative"
                              rules="required|email" name="Эл. адрес" mode="lazy" v-slot="{ errors }">

            <label for="form-program-field-email" class="form-label">Эл. адрес*</label>

            <input type="text"
                   class="form-control"
                   :disabled="signingContractStatus"
                   :class="errors.length ? 'is-invalid' : ''"
                   :placeholder="'example@mail.ru'"
                   :name="'form-program-field-email'"
                   id="form-program-field-email"
                   v-model="form['email']"/>

            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-email_err'">{{ err }}</span>
            </div>

          </ValidationProvider>

          <ValidationProvider ref="form-program-phone" tag="div" class="col-12 col-sm-4 mb-15px position-relative"
                              rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

            <label for="form-program-field-phone" class="form-label">Телефон*</label>

            <masked-input type="text"
                          class="form-control"
                          :disabled="signingContractStatus"
                          :class="errors.length ? 'is-invalid' : ''"
                          :placeholder="'+7 (999) 999-99-99'"
                          :name="'form-program-field-phone'"
                          id="form-program-field-phone"
                          :pattern="'+7 (111) 111-11-11'"
                          v-model="form['phone']">

            </masked-input>

            <div v-if="errors.length" class="invalid-tooltip">
              <span v-for="err in errors" :key="'form-program-field-phone_err'">{{ err }}</span>
            </div>

          </ValidationProvider>
        </div>

        <template v-if="temp_group_program && temp_group_program.length">
          <div class="fs-18px fw-600 mb-10px mt-10px">Состав программы:</div>
          <div class="d-flex flex-wrap gap-5">
            <div class="mw-sm-45" v-for="group in temp_group_program" :key="`block_${group.id}`"
                 @click="group.type !== 'mandatory' ? setGroupProgramId(group.id) : null"
                 v-if="group.blocks.length">
              <div class="form-check fs-16px fw-500 mb-12px">
									<span :disabled="true"
                        :class="`form-check-input form-check-input-bordered checked opacity-75`"
                        v-if="group.type === 'mandatory'"/>
                  <span class="form-check-input form-check-input-bordered"
                        :class="form.group_program.indexOf(group.id) >= 0 ? 'checked' : ''"
                        v-else/>
                  <label class="form-label">{{ group.name }}</label>
              </div>
              <div v-for="item in group.blocks" :key="`block_${group.id}_${item.id}`" class="mb-8px">
                - {{ item.name }}
              </div>
            </div>
          </div>
          <div class="fs-18px fw-600 mb-8px mt-16px">Стоимость:</div>
          <div class="fs-16px">{{ form.price ? $formatPrice(form.price) : 0 }} Р</div>
        </template>

        <button type="submit" class="btn btn-primary px-30px py-10px mt-30px w-200px" :disabled="signingContractStatus">
          Сохранить
        </button>

      </ValidationObserver>
    </div>

    <notifications group="center" position="top center" :duration="10000">
      <template slot="body" slot-scope="props">
        <div :class="'vue-notification-template vue-notification ' + props.item.type">
          <div class="title">
            {{ props.item.title }}
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-times"></i>
          </a>
          <div v-html="props.item.text">
          </div>
        </div>
      </template>
    </notifications>

    <NeedProfileMessage/>
  </div>
</template>

<script>
import axios from 'axios'
import NeedProfileMessage from '@/components/elements/NeedProfileMessage'

export default {
  name: 'OrderAddListener',
  components: {NeedProfileMessage},
  data() {
    return {
      program: null,
      listener: null,
      form: {
        available_vo_spo: false,
        surname: '',
        name: '',
        patronymic: '',
        phone: '',
        email: '',
        snils: '',
        group_program: [],
        price: 0,
      },
      temp_group_program: []
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },
    token() {
      return this.$store.getters['auth/token']
    },
    idOrder() {
      return this.$route.params.id
    },
    idBid() {
      return this.$route.query?.ID_BID
    },
    idProgram() {
      return this.$route.query?.ID_PROGRAM
    },
    idListener() {
      return this.$route.query?.ID_LISTENER
    },
    formForWatch() {
      return JSON.parse(JSON.stringify(this.form))
    },
    order() {
      return this.idOrder ? this.$store.getters['orders/itemById'](this.idOrder) : null
    },
    bid() {
      return this.order && this.order.bids ? this.order.bids.find(bid => bid.id === this.idBid) : null
    },
    signingContractStatus() {
      if (this.bid?.status?.code) {
        switch (this.bid.status.code) {
          case 'waiting_auth_contact_face':
          case 'waiting_verification_by_contact_person':
          case 'waiting_auth_listeners':
          case 'waiting_listeners_fill_profile':
          case 'waiting_listener_consent_program':
            return false
          case 'signing_contract':
          case 'waiting_payment':
          case 'payed':
            return true
        }
      }
      return false
    },
  },
  watch: {
    formForWatch(newVal, oldVal) {
      this.calcPrice()
    },
  },
  created() {
    if (this.user && !this.user.need_edit_profile) {
      if (!this.program && !this.idListener) {
        this.getProgram().then(res => {
          this.updateData([res])
        })
      }
      if (this.idListener) {
        Promise.all([
          this.getProgram(),
          this.getListener(),
        ]).then(res => {
          this.updateData(res)
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('listeners/fetchListenerOrderItems', {idOrder: this.idOrder})
    this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
  },
  methods: {
    setGroupProgramId(groupId) {
      let tmpGroupProgramId = []
      this.temp_group_program.forEach(item => {
        if (item.type === 'mandatory') {
          tmpGroupProgramId.push(item.id)
        }
      })
      tmpGroupProgramId.push(groupId)
      this.form.group_program = tmpGroupProgramId
    },
    hasHistory() {
      return window.history.length > 2
    },
    getListener() {
      let path = ''
      let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.idListener}/get`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.idListener}/get`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.idListener}/get`
            break
        }
      })
      return axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
        data: {
          listener_id: this.idListener,
          bid_id: this.idBid,
        },
      })
          .then(res => res.data)
          .then(res => {
            if (res.code === 401) {
              Promise.all([
                this.$store.dispatch('orders/reset'),
                this.$store.dispatch('notifications/reset'),
                this.$store.dispatch('documents/resetDocuments'),
                this.$store.dispatch('user/resetUser'),
                this.$store.dispatch('listeners/resetListeners'),
                this.$store.dispatch('bids/reset'),
                this.$store.dispatch('auth/logout'),
              ]).then(() => {
                this.$router.push({path: '/login'})
              })
            }
            return {
              listener: res.data,
            }
          })
    },
    getProgram() {
      let path = ''
      let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/configurations/${this.idProgram}/get`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/configurations/${this.idProgram}/get`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/configurations/${this.idProgram}/get`
            break
        }
      })
      return axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
      })
          .then(res => res.data)
          .then(res => {
            if (res.code === 401) {
              Promise.all([
                this.$store.dispatch('orders/reset'),
                this.$store.dispatch('notifications/reset'),
                this.$store.dispatch('documents/resetDocuments'),
                this.$store.dispatch('user/resetUser'),
                this.$store.dispatch('listeners/resetListeners'),
                this.$store.dispatch('bids/reset'),
                this.$store.dispatch('auth/logout'),
              ]).then(() => {
                this.$router.push({path: '/login'})
              })
            }
            return {
              program: res.data,
            }
          })
    },
    updateData(res) {
      let program, listener
      res.forEach(item => {
        if (item.program) {
          this.program = program = item.program
        }
        if (item.listener) {
          this.listener = listener = item.listener
        }
      })

      this.temp_group_program = program.configuration.group_program

      if (!listener && program) {

        this.calcPrice()

      } else if (program && listener) {
        this.form.group_program = Object.keys(Object.groupBy(listener.configs, ({group_id}) => group_id)).map(key => Number(key))

        this.form = {
          bid_id: this.idBid,
          listener_id: listener.id,
          available_vo_spo: listener.info.available_vo_spo || false,
          surname: listener.info.surname,
          name: listener.info.name,
          patronymic: listener.info.patronymic,
          phone: this.$formatPhone(listener.info.phone),
          email: listener.info.email,
          snils: listener.info.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`),
          group_program: this.form.group_program,
          price: this.form.price,
        }
        this.calcPrice()
      }
    },
    calcPrice() {
      let price = 0
      for (let j = 0; j < this.form.group_program.length; j++) {
        if (this.form.group_program[j].checked) {
          for (let k = 0; k < this.form.group_program[j].blocks.length; k++) {
            price += Number(this.form.group_program[j].blocks[k].price)
          }
        }
      }
      this.form.price = price
    },
    async submitForm() {
      if (this.signingContractStatus) return false
      const isValid = await this.$refs['observer-form-listener'].validate()
      if (isValid) {
        const formData = new FormData()

        formData.append('surname', this.form.surname)
        formData.append('name', this.form.name)
        formData.append('patronymic', this.form.patronymic)
        formData.append('email', this.form.email)
        formData.append('phone', this.form.phone)
        formData.append('snils', this.form.snils)
        formData.append('available_vo_spo', this.form.available_vo_spo ? 1 : 0)
        formData.append('program', this.idProgram)

        this.form.group_program.forEach(id => {
            formData.append(`group_program_id[${id}]`, 1)
        })

        let path = ''
        if (this.idListener) {
          let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
          roles.forEach(role => {
            switch (role) {
              case 'contact_person':
                path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.idListener}/update`
                break
              case 'sales_department_manager':
                path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.idListener}/update`
                break
              case 'head_department_manager':
                path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.idListener}/update`
                break
            }
          })
        } else {
          let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
          roles.forEach(role => {
            switch (role) {
              case 'contact_person':
                path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/listeners/create`
                break
              case 'sales_department_manager':
                path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/listeners/create`
                break
              case 'head_department_manager':
                path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/listeners/create`
                break
            }
          })
        }

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
            .then(res => res.data)
            .then(res => {
              if (res) {
                if (res.message) {
                  this.$notify({
                    group: 'center',
                    title: `Ошибка!`,
                    text: res.message,
                    type: 'error',
                  })
                } else {
                  this.$notify({
                    group: 'center',
                    title: ``,
                    text: this.idListener ? 'Слушатель обновлен' : `Слушатель создан!`,
                    type: 'success',
                  })
                  this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
                  this.$router.push({path: `/orders/${this.idOrder}/`})
                }
              }
            })
      }

    },
  },
}
</script>
