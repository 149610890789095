<template>
  <div>
    <label :for="`form_${_id}`" class="form-label" v-if="nameField">{{ nameField }}</label>

    <div class="position-relative">
      <template class="position-relative" v-if="typeField === 'date'">
        <date-picker
            v-model="inputVal"
            :disabled="disabled"
            :class="{'is-invalid': errorsField.length, 'disabled': disabled}"
            :placeholder="placeholderField"
            @dp-change="onChange"
            :config="options"></date-picker>
      </template>

      <template v-else-if="typeField === 'text'">
        <input type="text"
               class="form-control"
               v-model="inputVal"
               v-filter="filterField"
               :disabled="disabled"
               :class="errorsField.length ? 'is-invalid' : ''"
               :placeholder="placeholderField"
               :maxlength="maxLength"
               :id="`form_${_id}`"/>
      </template>

      <template v-if="typeField === 'textarea'">
			<textarea class="form-control"
                rows="5"
                :class="errorsField.length ? 'is-invalid' : ''"
                :placeholder="placeholderField"
                v-filter="filterField"
                :disabled="disabled"
                v-model="inputVal"
                :maxlength="maxLength"
                :id="`form_${_id}`"></textarea>
      </template>

      <div v-if="errorsField.length" class="invalid-tooltip">
        <span v-for="err in errorsField" :key="`form_${_id}_error`">{{ err }}</span>
      </div>

      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
import uniqid from 'uniqid'

export default {
  name: 'InputField',
  props: {
    'typeField': {
      type: String,
      default: 'text',
    },
    'nameField': {
      type: String,
      default: '',
    },
    'placeholderField': {
      type: String,
      default: '',
    },
    'errorsField': {
      type: Array,
      default: () => [],
    },
    'filterField': {
      type: String,
      default: '',
    },
    'maxLength': {},
    'value': {},
    'icon': {},
    'disabled': {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {
        format: 'DD.MM.YYYY',
        useCurrent: false,
        locale: 'ru',
      },
    }
  },
  computed: {
    _id() {
      return uniqid()
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
