<template>
  <div class="mw-900px">
    <div class="alert alert-success d-flex align-items-center mb-24px" v-if="user.personal_matter_documents_verified">
      <i class="fa fa-check me-12px"></i>
      Документы проверены
    </div>
    <div class="columns-2">
      <template v-for="field in documentsTypes" v-if="form[field.code] && documentsTypes && documentsTypes.length">
        <div class="columns-item mb-15px position-relative" :key="`documents_form_${field.code}`">
          <label class="form-label">{{ field.name }}</label>
          <InputFileField @setFiles="e => setFiles(e, field.code)" :name="field.code" :simple="true"
                          :file-name="field.name" :set-date="true" :default-file-name="form[field.code].name"/>
          <div v-if="errors[field.code]" class="invalid-tooltip">{{ errors[field.code] }}</div>
        </div>

        <div class="columns-item" v-if="field.template_file_name && field.code === 'consent_processing_personal_data'">
          <div class="p-15px border-radius bg-gray-10">
            Вам необходимо скачать файл "Согласие на обработку персональных данных», распечатать, поставить свою
            персональную подпись, отсканировать или сфотографировать данный документ и прикрепить файл в это поле выше
            <div class="d-flex mt-12px">
              <IconDocuments :width="30" :height="30"/>
              <div class="ms-12px">
                Согласие на обработку персональных данных<br/>
                <a :href="field.template_file_name" :download="`Согласие на обработку персональных данных`"
                   class="text-decoration-underline">Скачать</a>
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>

    <button type="submit" class="btn btn-primary px-30px py-10px mt-30px" :disabled="!hasChanges && user.given_consent_processing_personal_data" @click="sendForm"
            v-if="documentsTypes.length">Отправить на проверку
    </button>

    <b-modal :id="'DocumentsMessageModal'" size="md" centered>
      <template slot="modal-header">
        <h4 class="modal-title w-100 text-center">Спасибо!</h4>
        <a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('DocumentsMessageModal')"></a>
      </template>

      <div class="text-center">
        Ваши данные направлены на проверку ответственным менеджером
      </div>

      <div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
        <button type="button"
                @click="$bvModal.hide('DocumentsMessageModal'), $router.push({path: '/education/programs'})"
                class="btn btn-primary btn-lg">
          OK
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputFileField from '@/components/elements/InputFile'
import IconDocuments from '@/components/icons/IconDocuments'

export default {
  name: 'PersonalDataDocumentsForm',
  components: {IconDocuments, InputFileField},
  data() {
    return {
      form: {},
      errors: {},
      hasChanges: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },
    roles() {
      return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
    },
    isListener() {
      return this.user && this.roles.indexOf('listener') >= 0
    },
    documents() {
      return this.$store.getters['user/main_documents'] || []
    },
    documentsTypes() {
      return this.$store.getters['dictionaries/items']('listener_personal_matter') || []
    },
  },
  watch: {
    documentsTypes(newVal, oldVal) {
      newVal.forEach(type => {
        let name = ''
        if (this.documents.length) {
          this.documents.forEach(item => {
            if (type.code === item.type_code) {
              name = item.file_name
            }
          })
        }
        this.form[type.code] = {
          file: null,
          type: type.code,
          name: name,
        }
        this.errors[type.code] = false
      })
    },
  },
  mounted() {
    this.$store.dispatch('dictionaries/fetchDictionary', {type: 'type_documents', subtype: 'listener_personal_matter'})
    this.$store.dispatch('user/fetchGetUserMainDocuments')
  },
  methods: {
    setFiles(e, code) {
      this.form[code].file = Array.isArray(e) ? e[0] : e
      this.hasChanges = true
    },
    sendForm() {
      let documents = []
      let data = new FormData()
      for (let key in this.form) {
        if (this.form.hasOwnProperty(key) && this.form[key].file) {
          documents.push({document_type: this.form[key].type})
          data.append(`document_${this.form[key].type}`, this.form[key].file)
        }
      }
      documents.forEach((item, index) => {
        data.append(`documents[${index}][document_type]`, item.document_type)
        data.append(`documents[${index}][comment]`, 'comment')
      })
      //data.append('documents', JSON.stringify(documents))
      if (documents.length) {
        this.$store.dispatch('user/fetchUpdateUserMainDocuments', data).then(res => {
          if (res.message && !res.success) {
            this.$notify({
              group: 'center',
              title: ``,
              text: res.message,
              type: 'error',
            })
          } else if (res.message) {
            this.$notify({
              group: 'center',
              title: ``,
              text: res.message,
              type: 'success',
            })
          } else {
            this.$bvModal.show('DocumentsMessageModal')
            this.$store.dispatch('user/fetchGetUser')
          }
        })
      }
    },
  },
}
</script>
